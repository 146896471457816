<template>
  <layout title="Plano de carreira" :options="[]">
    <div>
      <v-flex style="cursor: pointer;" xs12>
        <div
          style="width: 100%; flex-direction: column; display: flex;justify-content: center; align-items: center; background-size: 100% 200px; border-radius: 6px;"
        >
          <v-card
            color="grey darken-4"
            class="pa-2"
            style="border-radius: 0px; width: 100%; flex-direction: column; display: flex;justify-content: center; align-items: center;"
          >
            <v-avatar size="100" class="animatedelevation-1">
              <v-img :src="graduacao.imagem"></v-img>
            </v-avatar>
            <h3
              style="text-shadow: 0 0 6px #000, 0 0 5px #000; color: #e0991e; font-size: 20pt"
              class="fonte white--text"
            >
              {{ graduacao.graduacao || 'Sem graduacão' }}
            </h3>
            <v-divider dark></v-divider>

            <!-- <v-list dark dense class="pa-0 ma-0">
              <v-list-item>
                <v-avatar>
                  <v-icon :color="$primaria">mdi-trending-up</v-icon>
                </v-avatar>
                <span class="font-weight-bold grey--text fonte orange--text"
                  >Você tem {{ graduacao.pontos || 0 }} pontos</span
                >
              </v-list-item>

              <v-list-item>
                <v-avatar>
                  <v-icon :color="$primaria">mdi-network-outline</v-icon>
                </v-avatar>
                <span class="font-weight-bold grey--text fonte"
                  >Você tem {{ graduacao.indicados_diretos || 0 }} indicados
                  diretos ativos</span
                >
              </v-list-item>

              <v-list-item>
                <v-avatar>
                  <v-avatar>
                    <v-img src="/images/ouro.png"></v-img>
                  </v-avatar>
                </v-avatar>
                <span class="font-weight-bold grey--text fonte"
                  >Você tem {{ graduacao.indicados_diretos || 0 }} indicados
                  diretos</span
                >
              </v-list-item>

              <v-list-item>
                <v-avatar>
                  <v-img src="/images/safira.png"></v-img>
                </v-avatar>
                <span class="font-weight-bold grey--text fonte"
                  >Você tem {{ graduacao.indicados_diretos || 0 }} indicados
                  diretos
                </span>
              </v-list-item>

              <v-list-item>
                <v-avatar>
                  <v-img src="/images/rubi.png"></v-img>
                </v-avatar>
                <span class="font-weight-bold grey--text fonte"
                  >Você tem {{ graduacao.indicados_ouro || 0 }} indicados
                  diretos no nível Gold</span
                >
              </v-list-item>

              <v-list-item>
                <v-avatar>
                  <v-img src="/images/esmeralda.png"></v-img>
                </v-avatar>
                <span class="font-weight-bold grey--text fonte"
                  >Você tem {{ graduacao.indicados_platina || 0 }} indicados
                  diretos no nível Safira</span
                >
              </v-list-item>

              <v-list-item>
                <v-avatar>
                  <v-img src="/images/diamante.png"></v-img>
                </v-avatar>
                <span class="font-weight-bold grey--text fonte"
                  >Você tem {{ graduacao.indicados_esmeralda || 0 }} indicados
                  diretos no nível Rubi</span
                >
              </v-list-item>

              <v-list-item>
                <v-avatar>
                  <v-img src="/images/black_diamante.png"></v-img>
                </v-avatar>
                <span class="font-weight-bold grey--text fonte"
                  >Você tem {{ graduacao.indicados_rubi || 0 }} indicados
                  diretos no nível Esmeralda</span
                >
              </v-list-item>

              <v-list-item>
                <v-avatar>
                  <v-img src="/images/presidente.png"></v-img>
                </v-avatar>
                <span class="font-weight-bold grey--text fonte"
                  >Você tem {{ graduacao.indicados_diamante || 0 }} indicados
                  diretos no nível Diamond</span
                >
              </v-list-item>

              <v-list-item>
                <v-avatar>
                  <v-img src="/images/embaixador.png"></v-img>
                </v-avatar>
                <span class="font-weight-bold grey--text fonte"
                  >Você tem {{ graduacao.indicados_imperial || 0 }} indicados
                  diretos no nível Black Diamond</span
                >
              </v-list-item>

              <v-list-item>
                <v-avatar>
                  <v-img src="/images/royal.png"></v-img>
                </v-avatar>
                <span class="font-weight-bold grey--text fonte"
                  >Você tem {{ graduacao.indicados_imperial || 0 }} indicados
                  diretos no nível Presidente</span
                >
              </v-list-item>

              <v-list-item>
                <v-avatar>
                  <v-img src="/images/imperial.png"></v-img>
                </v-avatar>
                <span class="font-weight-bold grey--text fonte"
                  >Você tem {{ graduacao.indicados_imperial || 0 }} indicados
                  diretos no nível Embaixador</span
                >
              </v-list-item>
            </v-list> -->

            <v-divider class="mt-3"></v-divider>
            <div style="display: flex; flex-wrap: nowrap">
              <v-icon class="ml-3 mr-3" :color="$theme.primary">
                mdi-star
              </v-icon>
              <h3
                class="mt-4 mb-4 text-center orange--text fonte text-uppercase"
              >
                Confira os Bonus e Premiações
              </h3>
            </div>
            <div
              style="display: flex; flex-direction: row;width: 100%; flex-wrap: wrap"
              xs12
            >
              <v-flex
                v-for="plano in planos"
                :key="plano.nome"
                class="pa-2"
                style="cursor: pointer;"
                xs12
                md4
              >
                <div
                  style="width: 100%; flex-direction: column; display: flex;justify-content: center; align-items: center; border-radius: 6px;"
                >
                  <v-card
                    hover
                    color="#555"
                    height="280"
                    class="pa-1 pb-3 elevation-1"
                    style="border-radius: 6px; width: 100%; flex-direction: column; display: flex;justify-content: center; align-items: center;border-radius: 2px;"
                  >
                    <v-avatar size="70" class="elevation-3  ">
                      <v-img :src="plano.imagem"></v-img>
                    </v-avatar>
                    <h3
                      style="text-shadow: 0 0 6px #000, 0 0 5px #00; color: #e0991e; font-size: 12pt"
                      class="fonteCash orange--text"
                    >
                      {{ plano.nome }}
                    </h3>
                    <v-divider dark></v-divider>

                    <v-list dark width="300" dense class="pa-0 ma-0">
                      <v-list-item>
                        <v-avatar>
                          <v-icon :color="$theme.primary"
                            >mdi-trending-up</v-icon
                          >
                        </v-avatar>
                        <span class="font-weight-bold grey--text"
                          >{{ plano.pontos }} de pontos</span
                        >
                      </v-list-item>
                      <!-- <v-divider></v-divider>
                      <v-list-item>
                        <v-avatar>
                          <v-icon :color="$theme.primary"
                            >mdi-network-outline</v-icon
                          >
                        </v-avatar>
                        <span class="font-weight-bold grey--text">{{
                          plano.indicados_diretos
                        }}</span>
                      </v-list-item> -->
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-avatar>
                          <v-icon :color="$theme.primary"
                            >mdi-star-outline</v-icon
                          >
                        </v-avatar>
                        <span class="font-weight-bold grey--text">{{
                          plano.premiacao
                        }}</span>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </div>
              </v-flex>
            </div>
          </v-card>
        </div>
      </v-flex>
    </div>
  </layout>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      graduacao_atual: "Calculando...",
      graduacao_atual_imagem: "/images/logo.png",
      pontos: [],
      indicados_diretos: [],
      options: [],
      planos: [
        {
          nome: "Associado Bronze",
          imagem: "/images/bronze.png",
          pontos: "2 mil",
          // indicados_diretos: "5 indicados diretos",
          premiacao: "Certificado de reconhecimento e $120 Dólares"
        },
        {
          nome: "Associado Prata",
          imagem: "/images/prata.png",
          pontos: "4 mil",
          // indicados_diretos: "10 indicados diretos",
          premiacao: "$270 Dólares"
        },
        {
          nome: "Associado Ouro",
          imagem: "/images/ouro.png",
          pontos: "8 mil",
          // indicados_diretos: "5 indicados diretos e 2 indicados Gold",
          premiacao: "Table de 240 Dólares e Bônus de 200 Dólares"
        },
        {
          nome: "Associado Diamante",
          imagem: "/images/diamante.png",
          pontos: "16 mil",
          // indicados_diretos: "15 indicados diretos e 3 indicados Rubi",
          premiacao: "Televisor LED de Alta Definição ou Smartfone de Última geração $ 580 + Pacote de Férias em Resort de Luxo: $400"
        },
        {
          nome: "Diretor Bronze",
          imagem: "/images/diretor_bronze.png",
          pontos: "64 mil",
          // indicados_diretos: "20 indicados diretos e 3 indicados Rubi",
          premiacao: "Bônus Generoso em Dinheiro: $3000"
        },
        {
          nome: "Diretor Prata",
          imagem: "/images/diretor_plata.png",
          pontos: "260 mil",
          // indicados_diretos: "30 indicados diretos e 4 indicados Esmeralda",
          premiacao: "Carro Novo de Médio Porte: $80000 + Férias em Destino Cancun: $5000"
        },
        {
          nome: "Diretor Ouro",
          imagem: "/images/diretor_ouro.png",
          pontos: "1 Milhão e 100 mil",
          // indicados_diretos: "40 indicados diretos e 5 indicados Diamond",
          premiacao: "BMW 320I = 50 mil dores"
        },
        {
          nome: "Diretor Diamante",
          imagem: "/images/diretor_diamante.png",
          pontos: "5 Milhões",
          // indicados_diretos: "50 indicados diretos e 5 indicados Black Diamond",
          premiacao: "Land Rover no Valor de 125 mil dólares + Acesso a Cursos e Treinamentos Exclusivos: Viagem para Paris 50 mil"
        },
        {
          nome: "Presidente Executivo",
          imagem: "/images/presidente_executivo.png",
          pontos: "25 Milhões",
          // indicados_diretos: "50 indicados diretos e 5 indicados Presidente",
          premiacao: "$800000"
        },
        {
          nome: "Presidente Diamante",
          imagem: "/images/presidente_diamante.png",
          pontos: "150 Milhões",
          // indicados_diretos: "50 indicados diretos e 5 indicados Embaixador",
          premiacao: " Mansão de Luxo ou Propriedade Exclusiva: $2500000 + Fundo de Investimento Personalizado e Gerenciado: $2500000"
        }
      ],
      tab: 0,
      usuario: {
        block: 0,
        nome: "",
        cotas: []
      },
      graduacao: {}
    };
  },
  methods: {
    get() {
      this.$store.dispatch("up_charging");

      this.$axios
        .get("/graduacao/self")
        .then(res => {
          this.$store.dispatch("down_charging");
          this.graduacao = res.data;
        })
        .catch(res => {
          this.$store.dispatch("down_charging");
          this.$store.dispatch("snackbar_info", res);
        });
    }
  },
  created() {
    // this.get()
  }
};
</script>
